import React, { Component } from 'react';
import './App.css';
import { Dapparatus, Gas, ContractLoader, Transactions, Events, Scaler, Blockie, Address, Button } from "dapparatus"
import Web3 from 'web3';
import axios from 'axios';
import qrcodeImage from './qrcode.png'
import makermkr from './makermkr.png'
import Dropzone from 'react-dropzone'
let base64url = require('base64url')
let interval

let FALLBACK_WEB3_PROVIDER = "http://localhost:8545"

const URL = "https://backend.paperwallet.studio/"


var http = require('http');

const METATX = false /*{
  endpoint:"http://0.0.0.0:10001/",
  contract:"0xf5bf6541843D2ba2865e9aeC153F28aaD96F6fbc",
  //accountGenerator: "//account.metatx.io",
}*/

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      web3: false,
      account: false,
      gwei: 4,
      doingTransaction: false,
      url:"https://yoursite.com",
      amount:25,
      accounts: {},
      generating: false,
      airdropAmount:0.05
    }
  }
  handleInput(e){
    let update = {}
    update[e.target.name] = e.target.value
    this.setState(update)
  }
  componentDidMount(){
    interval = setInterval(this.poll.bind(this),15000)
    setTimeout(this.poll.bind(this),500)

    if(window.location.pathname){
     console.log("PATH",window.location.pathname,window.location.pathname.length,window.location.hash)
     if(window.location.pathname.indexOf("/pk")>=0){
       let tempweb3 = new Web3();
       let base64encodedPK = window.location.hash.replace("#","")
       let rawPK = tempweb3.utils.bytesToHex(base64url.toBuffer(base64encodedPK))
       this.setState({
          newPrivateKey:this.state.rawPK,
       })
       window.history.pushState({},"", "/");
     }
   }
  }
  poll() {
    let url = URL+"list/"+this.state.account
    console.log("url",url)
    axios.get(url).then(response => {
      // handle your response;
      console.log("LIST:",response)
      if(response&&response.data){
        this.setState({latestFile:response.data})
      }

    }).catch(error => {
      // handle your error
    });
  }
  render() {
    let {web3,account,contracts,tx,gwei,block,avgBlockTime,etherscan} = this.state
    let connectedDisplay = []
    let contractsDisplay = []
    if(web3){
      connectedDisplay.push(
       <Gas
         key="Gas"
         onUpdate={(state)=>{
           console.log("Gas price update:",state)
           this.setState(state,()=>{
             console.log("GWEI set:",this.state)
           })
         }}
       />
      )
      /*
      connectedDisplay.push(
        <ContractLoader
         key="ContractLoader"
         config={{DEBUG:true}}
         web3={web3}
         require={path => {return require(`${__dirname}/${path}`)}}
         onReady={(contracts,customLoader)=>{
           console.log("contracts loaded",contracts)
           this.setState({contracts:contracts},async ()=>{
             console.log("Contracts Are Ready:",this.state.contracts)
           })
         }}
        />
      )
      connectedDisplay.push(
        <Transactions
          key="Transactions"
          config={{DEBUG:false}}
          account={account}
          gwei={gwei}
          web3={web3}
          block={block}
          avgBlockTime={avgBlockTime}
          etherscan={etherscan}
          onReady={(state)=>{
            console.log("Transactions component is ready:",state)
            this.setState(state)
          }}
          onReceipt={(transaction,receipt)=>{
            // this is one way to get the deployed contract address, but instead I'll switch
            //  to a more straight forward callback system above
            console.log("Transaction Receipt",transaction,receipt)
          }}
        />
      )*/
      /*
      if(contracts){
        contractsDisplay.push(
          <div key="UI" style={{padding:30}}>
            <div>
              <Address
                {...this.state}
                address={contracts.YOURCONTRACT._address}
              />
            </div>
            broadcast string: <input
                style={{verticalAlign:"middle",width:400,margin:6,maxHeight:20,padding:5,border:'2px solid #ccc',borderRadius:5}}
                type="text" name="broadcastText" value={this.state.broadcastText} onChange={this.handleInput.bind(this)}
            />
            <Button color={this.state.doingTransaction?"orange":"green"} size="2" onClick={()=>{
                this.setState({doingTransaction:true})
                //tx(contracts.YOURCONTRACT.YOURFUNCTION(YOURARGUMENTS),(receipt)=>{
                //  this.setState({doingTransaction:false})
                //})
              }}>
              Send
            </Button>
            <Events
              config={{hide:false}}
              contract={contracts.YOURCONTRACT}
              eventName={"YOUREVENT"}
              block={block}
              onUpdate={(eventData,allEvents)=>{
                console.log("EVENT DATA:",eventData)
                this.setState({events:allEvents})
              }}
            />
          </div>
        )
      }
      */
    }
    let frontImage = ""
    console.log("latestFile:",this.state.latestFile)
    if(this.state.latestFile){
      console.log("DISPLAYING",this.state.latestFile)
      frontImage=(
         <div style={{position:'absolute',left:0,top:0,width:"100%",height:"100%"}}>
           <img style={{width:"100%",zIndex:-1}} src={"https://s3.amazonaws.com/paper-wallet-files/"+this.state.latestFile.Key} />
         </div>
       )
    }

    let csv = ""
    let count = 1
    let accountListsAndDownloads = []

    console.log("this.state.accounts",this.state.accounts)

    for(let code in this.state.accounts){

      console.log("CODE",code,this.state.accounts[code])

      let accountArray = this.state.accounts[code]

      let accounts = accountArray.map((account)=>{
        csv+=account+","+this.state.airdropAmount+"\\n"
        return (
          <div style={{fontSize:17,color:'#444444'}}>
            {account}
          </div>
        )
      })


      accountListsAndDownloads.push(
        <div style={{position:"relative",top:280+80*count++,width:425,margin: "0 auto",backgroundColor:"#ffffff",textAlign:"center",padding:20}}>

          {accounts}
          <div>
            <Button color={"blue"} size="3" onClick={()=>{
                window.open(URL+"download?code="+code+"&account="+this.state.account, '_blank');
              }}>
              Download
            </Button>
          </div>
          <div>
            <Button color={"orange"} size="3" onClick={()=>{
                http.get(URL+"remove?code="+code+"&account="+this.state.account, (res) => {
                  let accounts = this.state.accounts
                  delete accounts[code]
                  this.setState({accounts})
                })
              }}>
              Remove
            </Button>
          </div>
        </div>

      )
    }



    return (
      <div className="App">
        <Dapparatus

          config={{
            DEBUG:false,
            metatxAccountGenerator:false,
            requiredNetwork:['Unknown','Mainnet','xDai','Rinkeby','Ropsten'],
            hide:false
          }}
          metatx={METATX}
          fallbackWeb3Provider={new Web3.providers.HttpProvider(FALLBACK_WEB3_PROVIDER)}
          onUpdate={(state)=>{
           console.log("metamask state update:",state)
           if(state.web3Provider) {
             state.web3 = new Web3(state.web3Provider)
             this.setState(state)
           }
          }}
        />
        {connectedDisplay}
        {contractsDisplay}


        <div style={{position:"relative",top:80,width:425,height:550,margin: "0 auto",backgroundColor:"#ffffff"}}>
          <div style={{position:"absolute",left:"15%",width:"70%",height:"40%",top:-90}}>
            <p style={{textAlign:"center",color:"#888888",fontSize:35,paddingTop:0,opacity:0.3,letterSpacing:-2.5}}>FRONT PAGE</p>
          </div>
          <div style={{position:"absolute",left:"15%",width:"70%",height:"40%",top:"15%",border:(frontImage?"3px dashed rgba(128, 128, 128, .1)":"3px dashed #888888")}}>
            {frontImage}
          </div>
          <div style={{position:"absolute",left:"15%",width:"70%",height:"40%",top:"15%",border:(frontImage?"3px dashed rgba(128, 128, 128, .1)":"3px dashed #888888")}}>
            <Dropzone onDrop={acceptedFiles => {
              console.log(acceptedFiles)
              var formData = new FormData();
              formData.append('file', acceptedFiles[0]);
              axios.post(URL+'upload/'+this.state.account, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
                // handle your response;
                console.log("UPLOADED",response)
                setTimeout(this.poll.bind(this),250)
                setTimeout(this.poll.bind(this),500)
                setTimeout(this.poll.bind(this),1000)
                setTimeout(this.poll.bind(this),3000)
              }).catch(error => {
                // handle your error
                console.log("ERROR",error)
              });
            }}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>

                    <input {...getInputProps()} />
                    <p style={{textAlign:"center",color:"#555555",fontSize:18,paddingTop:30,opacity:(frontImage?0.13:1)}}>📥 Upload Logo</p>
                    <p style={{textAlign:"center",color:"#888888",fontSize:18,paddingTop:30,opacity:(frontImage?0.13:1)}}>(drag & drop here!)</p>
                  </div>
                </section>
              )}
            </Dropzone>

          </div>
          <div style={{position:"absolute",width:"100%",top:"80%",fontSize:14,textAlign:'center',color:'#666666'}}>
              0x1234...5678
          </div>
        </div>


        <div style={{position:"relative",top:180,width:425,height:550,margin: "0 auto",backgroundColor:"#ffffff"}}>
          <div style={{position:"absolute",left:"15%",width:"70%",height:"40%",top:-90}}>
            <p style={{textAlign:"center",color:"#888888",fontSize:35,paddingTop:0,opacity:0.3,letterSpacing:-2.5}}>INSIDE PAGE</p>
          </div>

          <div style={{position:"absolute",width:"100%",top:"20%",fontSize:14,textAlign:'center',color:'#666666'}}>
              <div>
                visit <input style={{border:"none",borderBottom:"1px solid #DDDDDD",fontFamily:"sans-serif",fontSize:14,fontWeight:"bolder",color:'#666666'}} name="url" value={this.state.url} onChange={this.handleInput.bind(this)}/> and scan:
              </div>
              <div>
                <img src={qrcodeImage} />
              </div>
          </div>
        </div>


        <div style={{position:"relative",top:280,width:425,height:110,margin: "0 auto",backgroundColor:"#ffffff",textAlign:"center",padding:20}}>
          <div style={{position:"absolute",left:"15%",width:"70%",height:"40%",top:-90}}>
            <p style={{textAlign:"center",color:"#888888",fontSize:35,paddingTop:0,opacity:0.3,letterSpacing:-2.5}}></p>
          </div>
          Amount: <input style={{border:"none",borderBottom:"1px solid #DDDDDD",fontFamily:"sans-serif",fontSize:24,width:80,fontWeight:"bolder",color:'#666666'}} name="amount" value={this.state.amount} onChange={this.handleInput.bind(this)}/>
          <div style={{opacity:this.state.generating?0.25:1}}>
            <Button color={"green"} size="3" onClick={()=>{
                if(this.state.generating){
                  return false;
                }
                this.setState({generating:true})

                http.get(URL+"generate?account="+this.state.account+"&amount="+this.state.amount+"&url="+encodeURI(this.state.url), (res) => {
                  res.on('data', (chunk) => {
                    let str = chunk.toString()
                    let parts = str.split("\n")
                    for(let p in parts){
                      let thisLine = parts[p]
                      console.log("LINE:",thisLine)
                      if(thisLine.indexOf("PWDOWNLOADCODE:")>=0){
                        let code = thisLine.replace("PWDOWNLOADCODE:","")
                        console.log("code:",code)
                        this.setState({code:code})
                      }else{
                        if(thisLine.indexOf("Generated: ")>=0){
                          let address = thisLine.replace("Generated: ","")
                          let accounts = this.state.accounts
                          if(!accounts[this.state.code]) accounts[this.state.code] = []
                          accounts[this.state.code].push(address)
                          this.setState({accounts})
                        }
                      }
                    }

                  });
                  res.on('end', () => {
                    console.log("END");
                    this.setState({generating:false})
                  });
                });
              }}>
              Generate
            </Button>
          </div>
        </div>

        {accountListsAndDownloads}


        <div style={{position:"relative",top:320+80*count++,width:425,height:110,margin: "0 auto",backgroundColor:"#ffffff",textAlign:"center",padding:20}}>
          <div style={{position:"absolute",left:"15%",width:"70%",height:"40%",top:-90}}>
            <p style={{textAlign:"center",color:"#888888",fontSize:35,paddingTop:0,opacity:0.3,letterSpacing:-2.5}}></p>
          </div>
          Airdrop: <input style={{border:"none",borderBottom:"1px solid #DDDDDD",fontFamily:"sans-serif",fontSize:24,width:80,fontWeight:"bolder",color:'#666666'}} name="airdropAmount" value={this.state.airdropAmount} onChange={this.handleInput.bind(this)}/>
          <div>
            <Button color={"blue"} size="3" onClick={()=>{
                window.open("https://multisender.app/?csv="+csv,"_blank")
              }}>
              MultiSender.app
            </Button>
          </div>
        </div>

        <div style={{position:"relative",top:120+80*count++,width:425,height:110,margin: "0 auto"}}>
          <div style={{marginTop:200,padding:"25%",textAlign:"center",fontSize:16}}>
              <div>
                SPONSORED BY
              </div>
              <div>
                <img src={makermkr} style={{maxWidth:150}}/>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
